import * as React from "react"
import { BOOKENDS_SWAN_STYLES } from "@vp/esi-bookends"
import { SwanProvider, useBrowserClasses, SWAN_STYLE_KEY_MAP, ScreenClassProvider } from "@vp/swan"

export const STYLE_KEYS_TO_LOAD = [
  ...BOOKENDS_SWAN_STYLES,
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.grid,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.popover,
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.carousel,
  SWAN_STYLE_KEY_MAP.standardTile,
  SWAN_STYLE_KEY_MAP.accordion,
  SWAN_STYLE_KEY_MAP.pricing,
  SWAN_STYLE_KEY_MAP.hidden,
  SWAN_STYLE_KEY_MAP.visible,
  SWAN_STYLE_KEY_MAP.utility,
  SWAN_STYLE_KEY_MAP.banner,
]

type SwanConfigurationProps = {
  locale: string
  tenant: string
}

export const SwanConfiguration: React.FC<SwanConfigurationProps> = ({ locale, tenant, children }) => {
  useBrowserClasses(true)
  return (
    <SwanProvider swanLocale={locale} swanTenant={tenant}>
      <ScreenClassProvider>{children}</ScreenClassProvider>
    </SwanProvider>
  )
}
